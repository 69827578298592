.amplify-button--primary {
  background: #3c3c3c !important;
}

.amplify-authenticator {
  background-color: #3c3c3c;
}
[data-amplify-authenticator] {
  --amplify-components-authenticator-router-box-shadow: 0 0 16px
    var(--amplify-colors-overlay-30);
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding: var(--amplify-space-medium)
    var(--amplify-space-xl) var(--amplify-space-xl);
  --amplify-components-button-primary-background-color: var(
    --amplify-colors-neutral-100
  );
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px
    var(--amplify-colors-blue-60);
  --amplify-components-tabs-item-active-border-color: var(
    --amplify-colors-neutral-100
  );
  --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
  --amplify-components-tabs-item-active-color: var(--amplify-colors-black-100);
  --amplify-components-button-link-color: var(--amplify-colors-black-80);
}
