.añadiranexoboton {
  color: azure !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1) !important;
  background: #61d1b5 !important;
  border-radius: 10px !important; /* Bordes redondeados */
  display: flex !important;
  flex-direction: row-reverse !important;
  align-items: center !important;
  gap: 20px;
  width: 140px !important;
  height: 40px !important;
  padding-top: 10px !important;
}

.logoImage {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 200px; /* Ajusta el tamaño según sea necesario */
  height: auto;
  z-index: 10; /* Asegúrate de que esté visible */
}

.añadiranexoboton:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2) !important;
}

/* Estilos para el mensaje de grid vacío */
.emptyRecordContent {
  display: flex;
  /* Asegúrate de que el contenido también use flex */
  flex-direction: column;
  /* Coloca el ícono y el texto en columna */
  align-items: center;
  /* Centra el contenido en el eje horizontal */
  justify-content: center;
  /* Centra el contenido en el eje vertical */
  text-align: center;
  margin-top: 0;
  /* Elimina márgenes para centrar mejor */
}
.emptyRecordContent {
  text-align: center;
}

.emptyRecordContent p {
  font-weight: 350;
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
  margin-top: 20px;
}

.emptyRecordIcon {
  font-size: 40px;
  color: #61d1b5;
}
