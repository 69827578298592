.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 1rem;
    background: linear-gradient(to bottom, #ffffff, #f9fafb);
  }
  
  .header {
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 1rem;
   
  }
  
  .description {
    color: #4b5563;
    max-width: 48rem;
    margin: 0 auto;
    font-size: 1.125rem;
  }
  
  .statsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;
  }
  
  .card {
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    position: relative;
  }
  
  .card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(to right, #61D1B5, #4BA997);
  }
  
  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 0.5rem;
  }
  
  .icon {
    width: 3rem;
    height: 3rem;
    color: #61D1B5;
  }
  
  .number {
    font-size: 3rem;
    font-weight: 700;
    color: #61D1B5;
  }
  
  .cardContent {
    padding: 0 1.5rem 1.5rem;
  }
  
  .cardDescription {
    font-size: 1.1rem;
    color: #414141;
    font-weight: 600;
  }
  
  .footer {
    text-align: center;
  }
  
  .link {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: #61D1B5;
    font-size: 1.125rem;
    font-weight: 500;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .link:hover {
    color: #4BA997;
  }
  
  .linkIcon {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
  
    .description {
      font-size: 1rem;
    }
  
    .statsGrid {
      grid-template-columns: 1fr;
    }
  }