/* Sobrescribe estilos globales de React Quill */
.ql-container {
  font-size: 14px; /* Ajusta el tamaño de la fuente */
  line-height: 1.5; /* Cambia el espaciado entre líneas */
  min-height: 140px !important; /* Ajusta la altura mínima */
}

.ql-editor {
  min-height: 140px !important; /* Ajusta la altura mínima */
  background-color: #ffffff; /* Cambia el color de fondo del área de edición */
  border-radius: 8px; /* Añade bordes redondeados */
  padding: 16px; /* Ajusta el espaciado interno */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Añade sombra */

  max-height: 140px !important; /* Ajusta la altura máxima */
  overflow-y: auto !important; /* Habilita el desplazamiento vertical */
}

.ql-toolbar {
  background-color: #d6eaff; /* Cambia el color de fondo de la barra de herramientas */
  border-radius: 8px; /* Bordes redondeados para la barra */
  padding: 20px !important;
  padding-top: 0px !important ;
}
/* Sobrescribe la etiqueta que se muestra en el dropdown de Quill */
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="comic-sans"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="comic-sans"]::before {
  content: "Comic Sans MS";
  font-family: "Comic Sans MS", cursive;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="garamond"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="garamond"]::before {
  content: "Garamond";
  font-family: Garamond, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="georgia"]::before {
  content: "Georgia";
  font-family: Georgia, serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="tahoma"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="tahoma"]::before {
  content: "Tahoma";
  font-family: Tahoma, sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="trebuchet-ms"]::before,
.ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="trebuchet-ms"]::before {
  content: "Trebuchet MS";
  font-family: "Trebuchet MS", sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="verdana"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="verdana"]::before {
  content: "Verdana";
  font-family: Verdana, sans-serif;
}

/* Sobrescribe la etiqueta en el menú */
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before {
  content: "Serif";
  font-family: serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before {
  content: "Sans Serif";
  font-family: sans-serif;
}

/* CLAVES PARA APLICAR FUENTE AL TEXTO */
.ql-font-comic-sans {
  font-family: "Comic Sans MS", cursive;
}

.ql-font-garamond {
  font-family: Garamond, serif;
}

.ql-font-georgia {
  font-family: Georgia, serif;
}

.ql-font-tahoma {
  font-family: Tahoma, sans-serif;
}

.ql-font-trebuchet-ms {
  font-family: "Trebuchet MS", sans-serif;
}

.ql-font-verdana {
  font-family: Verdana, sans-serif;
}

/* Serif */
.ql-font-serif {
  font-family: serif;
}

/* Sans Serif */
.ql-font-sans-serif {
  font-family: sans-serif;
}
