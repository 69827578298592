@keyframes slide-up {
  from {
    transform: translateY(50px); /* Comienza desde un poco más abajo */
    opacity: 0; /* Comienza invisible */
  }
  to {
    transform: translateY(0); /* Termina en la posición normal */
    opacity: 1; /* Termina totalmente visible */
  }
}

.textoContainer,
.imagenContainer {
  animation: slide-up 0.8s ease-out forwards; /* Aplica la animación */
}

/* Ajustes adicionales para garantizar la visibilidad al inicio */
.textoContainer,
.imagenContainer {
  opacity: 0; /* Inicia invisible para manejar la animación */
}

.fill {
  height: calc(100vh - 0px);
}

.fondoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%; /* Utiliza todo el ancho disponible */
  padding: 0 4%;
  padding-top: 100px;
}

.textoContainer,
.imagenContainer {
  flex: 1; /* Asigna un espacio igual a cada contenedor */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.textoContainer {
  flex-direction: column;
  color: #3c3c3c;
  min-width: 50%;
}

.textoContainer {
  padding-right: 2%; /* Espaciado entre texto e imagen */
  padding-bottom: 2%;
}

.titleheader {
 margin-bottom: 4%;
  margin-top: 2%;
  padding-right: 150px;
}

.nombreEmpresa,
.descripcionEmpresa {
  animation: slide-in-bottom 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.nombreEmpresa {
  font-size: 55px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 0;
}

.descripcionEmpresa {
  list-style-type: none;
  padding-top: 2%;
  padding-right: 10%;
}

.descripcionEmpresa p {
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 7%;
  padding-right: 210px;
}

.buttonFee {
  background-color: #61d1b5;
  color: #3c3c3c !important;
  font-size: 17px;
  font-weight: 600;
  padding: 0.8rem 1.6rem;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-decoration: none !important;
}

.buttonFee:hover {
  background-color: #4fb399;
}

.imagenContainer img {
  max-width: 100%; /* Asegura que la imagen no sobrepase su contenedor */
  height: auto;
  padding-right: 2%;
}

@keyframes fade-in-out {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.cambiandoPalabras {
  font-size: 55px;
  color: #61d1b5;
  margin-top: 0;
  font-weight: 600;
  height: 5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
}

.wordWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  will-change: transform, opacity;
}




@media (max-width: 1868px) {
  .descripcionEmpresa {
    padding-right: 40px;
  }

  
}

@media (max-width: 1368px) {
  .nombreEmpresa {
    font-size: 45px;
  }
  .titleheader {
    padding-right: 0px;
}

  .cambiandoPalabras {
    font-size: 45px;
  }

  .palabraGris {
    font-size: 45px;
  }

  .descripcionEmpresa {
    font-size: 13px;
  }
  .descripcionEmpresa p {

    padding-right: 0px;
  }
}

@media (max-width: 768px) {
  .fill {
    height: 100%;
  }

  .fondoContainer {
    flex-direction: column;
    padding-top: 40px;
  }

  .titleheader {
    padding-right: 0px;
}

  .textoContainer,
  .imagenContainer {
    width: 90%; /* Ajusta el ancho en dispositivos móviles */
    padding: 30px 0; /* Espaciado superior e inferior */
  }
  .imagenContainer {
    margin-bottom: 60px;
  }

  .textoContainer {
    padding-right: 0%;
    padding-left: 0;
    margin-bottom: 60px;
  }

  .nombreEmpresa {
    font-size: 35px;
  }

  .cambiandoPalabras {
    font-size: 35px;
  }

  .palabraGris {
    font-size: 35px;
  }

  .descripcionEmpresa {
    font-size: 13px;
  }
}

@media (max-width: 468px) {
  .fondoContainer {
    flex-direction: column;
    padding: 0;
  }

  .textoContainer,
  .imagenContainer {
    width: 90%; /* Ajusta el ancho en dispositivos móviles */
    padding: 0; /* Espaciado superior e inferior */
  }

  .textoContainer {
    padding-bottom: 12%;
    padding-top: 20%;
  }

  .descripcionEmpresa {
    padding-left: 1%;
    padding-right: 1%;
  }

  .nombreEmpresa {
    font-size: 43px;
  }

  .palabraGris {
    font-size: 43px;
  }

  .descripcionEmpresa p {
    font-size: 20px;
    padding: 5% 0;
    padding-bottom: 15%;
  }

  .cambiandoPalabras {
    font-size: 43px;
  }

  .titleheader {
    padding-right: 0px;
}
}
