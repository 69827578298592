@import "HomePipeline.css";

@import "Authenticator.css"; /* Ocultar el botón de "Create Account" */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import "./Syncfussion/editor.css";
@import "./DashboardLayoutSidebar.css";
@import "./Reactquileditor.css";
@import "./Syncfussion/filemanager.css";

/* Barra de desplazamiento vertical y horizontal */
::-webkit-scrollbar {
  width: 8px; /* Ancho para la barra vertical */
  height: 8px; /* Altura para la barra horizontal */
  border-radius: 8px;
}

/* Pista de la barra de desplazamiento */
::-webkit-scrollbar-track {
  background: #f8f8f8;
  border-radius: 8px;
}

/* Pulgar de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}

/* Pulgar en hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
  border-radius: 8px;
}


html,
body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;

  font-style: normal;
  font-variation-settings: "YOPQ" 300;
}
