.wrapper {
  display: flex;
  justify-content: space-around; /* Cambiado para distribuir mejor los elementos */
  align-items: center;
  height: 100vh; /* Ocupa el 100% de la altura de la pantalla */
  width: 100vw; /* Ocupa el 100% del ancho de la pantalla */
  padding: 20px;
  background-color: #ffffff;
}

.container {
  flex: 1;
  text-align: left;
  padding: 20px;
  max-width: 450px; /* Reducimos el tamaño máximo del formulario */
  margin-top: -50px;
}

.title {
  font-size: 28px; /* Aumentamos el tamaño de la fuente del título */
  color: #333;
}

.description {
  font-size: 20px; /* Aumentamos el tamaño de la fuente de la descripción */
  color: #666;
  margin-bottom: 20px;
}

.input {
  display: block;
  width: 100%; /* Hacemos que los inputs ocupen todo el ancho */
  padding: 10px;
  margin: 15px 0; /* Espaciado más uniforme entre los campos */
  font-size: 18px; /* Aumentamos el tamaño de la fuente en los inputs */
  border-radius: 4px;
  border: 1px solid #ccc;
}
.buttoncontainer {
  display: flex;
  justify-content: center; /* Centrar los botones */
  gap: 20px; /* Separar los botones */
  margin-top: 20px; /* Añadir separación entre los inputs y los botones */
}

.button {
  border: 2px solid #E7E7E7;
  border-radius: 30px;
  background-color: #61D1B5;
  color: #3C3C3C;
  padding: 15px 25px;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  text-align: center;
  width: 150px;
}

.button:hover {
  background-color: #FFFFFF;
  color: #3C3C3C;
  border-color: #61D1B5;
}

.animationContainer {
  flex: 1;
  max-width: 700px; /* Aumentamos el tamaño de la animación */
  margin-left: 20px;
  display: flex;
  justify-content: center; /* Centramos la animación dentro de su contenedor */
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column; /* Pone el formulario y la animación en columnas en pantallas pequeñas */
    justify-content: center;
    align-items: center;
  }

  .container {
    text-align: center;
    max-width: 100%;
  }

  .animationContainer {
    margin-left: 0;
    margin-top: 20px;
    max-width: 300px;
  }
}
