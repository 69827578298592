.container {
  display: flex;
  align-items: stretch;
  position: relative;
  background-color: #ffffff;
  width: 100%;
}
/* Desactivar la scrollbar horizontal y estilos de scrollbar */
.container {
  overflow: auto;
  scrollbar-width: thin; /* Estilo de scrollbar delgado */
}

/* Para navegadores basados en WebKit (Chrome, Safari) */
.container::-webkit-scrollbar {
  height: 0; /* Oculta la scrollbar horizontal */
  width: 8px; /* Ancho de la scrollbar vertical */
}

.container::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color transparente para el "thumb" */
}

.container::-webkit-scrollbar-track {
  background-color: transparent; /* Color transparente para el "track" */
}

.a4Container {
  width: 100%;
  padding-top: 0;
  background-color: #eaeaea;

  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.menuItem:last-child {
  border-bottom: none;
}

.buttonContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.editContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menuButton {
  padding: 15px;
  margin-top: 30px;
  background-color: #484848;
  color: white;
  border: none;

  cursor: pointer;
  transition: background-color 0.3s;
}

.menuButton:hover {
  background-color: #3c3c3c;
}

.menuPrimaryButton {
  padding: 15px;
  margin-top: 30px;
  background-color: #009966;
  color: white;
  border: none;

  cursor: pointer;
  transition: background-color 0.3s;

  margin-left: 0%;
}

.menuPrimaryButton:hover {
  background-color: #007744;
}

.e-de-container {
  overflow-x: auto !important;
}

.e-de-property-pane-container {
  overflow: hidden !important;
  /* Opcional: ajustar el ancho máximo si fuera necesario */
  max-width: calc(100% - 50px);
}

.editor {
  width: 100%;
  height: 100vh;
  margin-top: 70px auto 0;
  overflow: hidden;
}

.customFileUpload {
  display: inline-block;
  width: 70%;
  padding: 15px;
  margin-top: 30px;
  background-color: #484848;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 6%;
  margin-right: calc(50px + 2%);
  text-align: center;
}

.hiddenFileInput {
  display: none;
}

.message-box {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  color: white;
  padding: 8px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}

.message-box.show {
  opacity: 1;
}

.success {
  background-color: none;
  margin-right: 20%;
  margin-left: 30%;
  font-weight: 600;
  color: #007744;
}

.error {
  background-color: none;
  margin-right: 20%;
  margin-left: 30%;
  font-weight: 600;
  color: rgb(226, 103, 103);
}
