.calculadoraContainer {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: 0 auto;
  padding: 16px;
  padding-top: 0;
}

.tituloContainer {
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 2rem;
  margin-bottom: 5px;
  color: #000000;
  font-weight: 700;
}

.contenidoContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.cardSliders {
  flex: 1;
  min-width: 300px;
  max-width: 1000px; /* Hacer la tarjeta más ancha */
  margin: 16px;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1) !important; /* Sombra más suave */
  border-radius: 16px; /* Bordes más redondeados */
}

.rightContainer {
  flex: 1;
  min-width: 300px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cardCostos,
.cardGrafica {
  margin: 16px;
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1) !important; /* Sombra más suave */
  border-radius: 16px; /* Bordes más redondeados */
}

.cardTitle {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.slidersContainer {
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.sliderItem {
  margin-bottom: 32px; /* Más espacio entre sliders */
}

.resultadosContainer {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.graficaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.chartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.chartWrapper {
  position: relative;
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centerContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.labelsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2d3958;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorBox {
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 10px;
}

.icon {
  font-size: 26px;
}

.text {
  text-align: center;
  font-size: 1.2rem;
}

.text p {
  margin: 0;
  font-size: 14px;
}

.resultadosTable {
  font-size: 1.2em;
  width: 50%;
  margin-top: 10px;
}

.resultadosTable td {
  padding: 8px;
}

.resultadoLabel {
  color: rgb(153, 153, 153);
  padding-right: 10px;
  text-align: left;
}

.resultadoValor {
  font-weight: bold;
  text-align: right;
}
.buttoncontainer{
  display: flex;
  justify-content: right;
}
.ctaButton {
  background-color: #60d1b5;
  color: #3c3c3c;
  font-size: 1rem;
  font-weight: 300;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  display: inline-flex; /* Activar flexbox */
  align-items: center; /* Alinear verticalmente */
  gap: 0.5rem; /* Espacio entre el texto y el ícono */
}

.ctaButton svg {
  transition: transform 0.3s ease; /* Suavizar la transformación */
}

.ctaButton:hover svg {
  transform: scale(1.1) translateX(5px); /* Incrementar tamaño del ícono */
}

.ctaButton:hover {
  background-color: #50c1a5;
}

@media (max-width: 768px) {
  .contenidoContainer {
    flex-direction: column;
    align-items: center;
  }

  .cardSliders,
  .cardCostos,
  .cardGrafica {
    max-width: 100%;
  }

  .chartWrapper {
    width: 300px;
    height: 300px;
  }

  .resultadosTable {
    font-size: 1em;
  }

  .resultadoLabel,
  .resultadoValor {
    font-size: 0.9em;
    text-align: center;
    display: block;
  }

  .labelsContainer .label .text p:nth-child(2) {
    font-size: 0.9em;
  }

  .cardTitle {
    font-size: 1.5rem;
  }
}
