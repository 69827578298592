.screenBlocker {
  width: 100%;
  height: 100vh; /* Ocupa toda la altura de la pantalla */
  background-color: white; /* Fondo blanco */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.blockerContent {
  display: flex;
  flex-direction: row; /* Colocamos los elementos uno al lado del otro */
  justify-content: space-between;
  align-items: center;

  border-radius: 20px;
  padding: 40px;

  max-width: 900px;
  width: 100%;
}

.textContainer {
  flex: 1; /* El texto ocupa una parte del espacio */
  padding-right: 20px; /* Espacio entre el texto y la animación */
}

.animationContainer {
  flex: 1; /* La animación ocupa la otra parte */
  display: flex;
  justify-content: center;
  align-items: center;
}

.textContainer h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  background: linear-gradient(
    90deg,
    #1976d2,
    #61d1b5
  ); /* Gradiente aplicado al título */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Hace que el texto tome el color del gradiente */
}

.textContainer p {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .blockerContent {
    flex-direction: column; /* En pantallas pequeñas, los elementos se apilan verticalmente */
    text-align: center;
  }

  .textContainer {
    padding-right: 0; /* Quitamos el padding en pantallas pequeñas */
    margin-bottom: 20px;
  }

  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1.1rem;
  }
}
