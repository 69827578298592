/* PrivacyPolicy.module.css */
.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;

  line-height: 1.6;
  color: #3c3c3c;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 5%;
  margin-bottom: 5%;
}

.header {
  color: #3c3c3c;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
}

.lastUpdated {
  text-align: center;
  color: rgb(152, 152, 152);
}

.subheader {
  color: #3c3c3c;
  border-bottom: 2px solid #61d1b5;
  padding-bottom: 5px;
}

.subheader.bold {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.subsectionTitle {
  font-weight: bold;
  margin: 1.2em 0 0.8em 0; /* Espaciado superior e inferior leve */
}

.legalBasesTitle {
  font-weight: bold;
  margin-top: 1em;
}

.list {
  list-style-type: none;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.listItem {
  padding: 5px 0;
  position: relative;
}

a {
  color: #007bff; /* Azul estándar para enlaces */
  text-decoration: none; /* Opcional: elimina el subrayado */
  cursor: pointer;
}

a:hover {
  text-decoration: underline; /* Subrayado al pasar el cursor */
  color: #0056b3; /* Un tono más oscuro al pasar el cursor */
}

.listItem::before {
  content: "•"; /* Puedes cambiar este símbolo por cualquier otro o incluso usar una imagen con `content: url(path_to_image)` */
  color: #3c3c3c; /* Color azul para los bullet points */
  position: absolute;
  left: -15px; /* Ajustar según necesidad */
  font-size: 20px; /* Tamaño del bullet point */
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }
}
