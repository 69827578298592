/* En tu App.css */
.dashboardLayout {
  display: flex; /* Utiliza flexbox para colocar los elementos uno al lado del otro */
  height: 100vh; /* Altura completa de la pantalla */
  background-color: #f4f4f5 !important;
}

.dashboardLayout > div {
  flex: 1;
  max-width: calc(100% - 75px) !important; /* Asegura que no se desborde */
  box-sizing: border-box; /* Incluye padding y border en el tamaño total */
  overflow: hidden; /* Oculta el contenido que se salga del contenedor */
  margin: 0 !important;
  margin-top: 50px !important;
  margin-right: 10px !important;
  padding: 0 !important;
  border-radius: 10px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.dashboardLayout > div:last-child {
  padding: 20px; /* Añadir espacio alrededor del contenido si es necesario */
  overflow-y: auto; /* Permite el scroll si el contenido es largo */
}

.dasboardContent {
  min-height: calc(100vh - 100px) !important;
  padding-top: 200px !important;
}

.css-1wvake5 {
  background-color: #ffffff !important;
  border-right-width: 0px !important;
  border-right-style: solid;
  border-color: #efefef;
  transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out;
  height: 100vh !important;
}

.css-1wvake5.sidebar-expanded {
  width: 190px !important;
  min-width: 190px !important;
}

/* Sidebar colapsado */
.css-1wvake5.sidebar-collapsed {
  width: 65px !important; /* Ancho cuando el sidebar está colapsado */
  min-width: 65px !important;
  transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out; /* Transición suave */
}

/* Estilo para el contenedor del toggle */
.toggle-container {
  position: fixed;
  left: 50px;
  padding: 10px;
  cursor: pointer;
  padding-left: 20px;
  padding-bottom: 20px;
}

/* Estilo para el ícono de toggle */
.toggle-icon {
  font-size: 1.3rem !important;
  color: #3c3c3c !important;
  margin-left: 10px;
}

#root
  > div.dashboardLayout
  > aside
  > div
  > nav
  > ul
  > li.ps-menuitem-root.botonMenuItem.css-1t8x7v1 {
  margin-bottom: 40px !important;
  margin-top: 40px !important;
} /* Margen debajo de crear doc */

#root
  > div.dashboardLayout
  > aside
  > div
  > nav
  > ul
  > li.ps-menuitem-root.botonMenuItem.css-1t8x7v1
  > a {
  padding-top: 25px !important;
  padding-bottom: 25px !important ;
}
/* Estilo de los botones de menú */
.ps-menu-button {
  display: flex;
  align-items: center;
  color: #727272 !important;
  padding-left: 0px !important;
  margin: 10px !important;
  margin-left: 0px !important;
  border-radius: 5px !important;
  border-top-right-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 15px !important;
  padding-bottom: 0px !important;
}

/* Estilo de los botones de menú */
.ps-menu-button:hover {
  background-color: #ededed !important;
}

/* textos */
.css-12w9als {
  font-size: 0.94rem !important;
}
/* Iconos (general, aplica a todos) */
.css-wx7wi4 {
  font-size: 1.3rem !important;
  margin-left: 20px !important;
  margin-right: 2px !important;
}

/*Icono de crear doc (Copy selector)*/
#root
  > div.dashboardLayout
  > aside
  > div
  > div:nth-child(1)
  > nav
  > ul
  > li.ps-menuitem-root.botonMenuItem.css-1t8x7v1
  > a
  > span.ps-menu-icon.css-wx7wi4 {
  margin-left: 10px !important;
}

/* Ajustes cuando el sidebar está colapsado */
.sidebar-collapsed .ps-menu-button i {
  margin-right: 0; /* Sin espacio cuando colapsado */
}

.sidebar-collapsed .ps-menu-button .ps-menu-label .css-12w9als {
  /* Oculta el texto cuando colapsado */
  display: none;
}

/* Estilo para el menú seleccionado */
.selectedMenuItem > .ps-menu-button {
  color: #3c3c3c !important;
  font-weight: 600 !important;
  background-color: transparent !important;
}

.selectedMenuItem > .ps-menu-button .css-wx7wi4 {
  color: #61d1b5 !important;
}

.botonMenuItem > .ps-menu-button {
  background-color: #d7f3ff !important;
  margin-top: 70px !important;
  margin-bottom: 20px !important;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  width: 80% !important;
  max-width: none !important;
  border-radius: 30px !important;
  transition: transform 0.4s ease, box-shadow 0.4s ease !important;
  margin-left: 10px !important;
}

.botonMenuItem > .ps-menu-button:hover {
  transform: translateY(-1px) !important;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3) !important;
}

.botonMenuItem > .ps-menu-button .css-12w9als {
  color: #3c3c3c !important;
  font-weight: 550 !important;
  font-size: 0, 9375rem !important; /* Reducimos el tamaño de fuente a 0.875rem (equivalente a 14px) */
}

.botonMenuItem > .ps-menu-button .css-wx7wi4 {
  font-size: 1.9rem !important; /* Reducimos el tamaño del icono */
  color: #3c3c3c !important;
}

.css-dip3t8 {
  background-color: #f4f4f5 !important;
  /* Ajustes de flex para que el contenido crezca en la parte superior y ancle “Settings” al final */
  display: flex;
  flex-direction: column;
  height: 100vh !important;
  /* Si quieres transiciones como antes, puedes añadirlos también */
  transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out;
  border-right-width: 0px !important;
  border-right-style: solid;
  border-color: #efefef;
}
