.bannerContainer {
  width: 70%;
  margin: 0 auto;
  background-color: white;
  overflow: hidden;
  position: relative;
}

.wrapper {
  text-align: center;
  font-size: 25px;
  color: #cecece;
}

.carouselTrack {
  display: flex;
  width: fit-content;
  transition: transform 0.03s linear;
}

.logo {
  flex: 0 0 auto;
  margin: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoImage {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.fadeLeft,
.fadeRight {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 200px;
  z-index: 1;
  pointer-events: none;
}

.fadeLeft {
  left: 0;
  background: linear-gradient(to right, white, transparent);
}

.fadeRight {
  right: 0;
  background: linear-gradient(to left, white, transparent);
}
