/* PaymentError.module.css */

.errorSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 20px auto;
    text-align: center;
  }
  
  .errorSection h2 {
    color: #d9534f; /* Color rojo para resaltar el error */
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .errorSection p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .submitButton {
    background-color: #3c3c3c; /* Color rojo para el botón de error */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 200px;
    text-align: center;
  }
  
  .submitButton:hover {
    background-color: #606060; /* Un rojo más oscuro para el hover */
  }
  