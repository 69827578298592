.e-headercell .e-headercelldiv .e-headertext {
  display: block !important;
  color: #3c3c3c !important; /* Cambia esto para que contraste con tu fondo */
  font-weight: 500 !important;
  font-size: 16px !important;
  padding-right: 0px !important;
}
.e-grid .e-headercell {
  background-color: transparent !important; /* Override the header background color */
  padding-right: 0px !important;
}

.e-gridheader.e-lib.e-droppable {
  padding-right: 0px !important;
}

.e-row {
  cursor: pointer;
}

.e-templatecell {
  display: flex;
  align-items: center;
}

.e-grid {
  border-radius: 70px !important;
  border: 0px !important;
  background-color: transparent !important;
}
.e-grid .e-gridheader {
  border: 0px !important;
}

/* ESTILOS DATA GRID DE BORRADORES */

.e-grid .e-row .e-templatecell .documentIcon {
  position: absolute;
  color: #115abe; /* Cambia el color del ícono a azul */
  margin-right: 8px; /* Añade un poco de espacio a la derecha del ícono */
  width: 30px; /* Define el tamaño predeterminado del ícono */
  transition: transform 0.3s ease;
  font-size: 30px;
}

.e-grid .e-row:hover .e-templatecell .documentIcon {
  transform: scale(
    1.3
  ); /* Aumentar el tamaño del ícono al hacer hover en la fila */
}

.e-grid .e-row .e-templatecell .documentTitle {
  margin-left: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px; /* Ajusta este valor según el ancho deseado */
  text-decoration: underline;
  transition: transform 0.3s ease;
}

.e-grid .e-row:hover .e-templatecell .documentTitle {
  transform: scale(
    1.02
  ); /* Aumentar el tamaño del ícono al hacer hover en la fila */
}

/* ESTILOS DATA GRID DE COMPARTIDOS */

.e-grid .e-row .e-templatecell .documentIconPending {
  position: absolute;
  color: #bebebe; /* Cambia el color del ícono a azul */
  margin-right: 8px; /* Añade un poco de espacio a la derecha del ícono */
  width: 24px; /* Define el tamaño predeterminado del ícono */
  transition: transform 0.3s ease;
  font-size: 24px;
}

.e-grid .e-row .e-templatecell .documentIconPendingNonOpened {
  position: absolute;
  color: #5f9cff; /* Cambia el color del ícono a azul */
  margin-right: 8px; /* Añade un poco de espacio a la derecha del ícono */
  width: 24px; /* Define el tamaño predeterminado del ícono */
  transition: transform 0.3s ease;
  font-size: 24px;
}

.e-grid .e-row:hover .e-templatecell .documentIconPending {
  transform: scale(
    1.3
  ); /* Aumentar el tamaño del ícono al hacer hover en la fila */
}

.e-grid .e-row .e-templatecell .documentPendingCell {
  position: absolute;
  background-color: #fef3c7;
  color: #b45309; /* Cambia el color del ícono a azul */
  margin-right: 8px; /* Añade un poco de espacio a la derecha del ícono */
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 500;
}

.e-grid .e-row .e-templatecell .documentAprobadoCell {
  position: absolute;
  background-color: #d1fae5;
  color: #047857; /* Cambia el color del ícono a azul */
  margin-right: 8px; /* Añade un poco de espacio a la derecha del ícono */
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 500;
}

.e-grid .e-row .e-templatecell .documentCanceladoCell {
  position: absolute;
  background-color: #ffccc4;
  color: #b44b4b; /* Cambia el color del ícono a azul */
  margin-right: 8px; /* Añade un poco de espacio a la derecha del ícono */
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 500;
}

.e-grid .e-row .e-templatecell .enviado {
  background-color: #fff9df;
  color: #b45309; /* Cambia el color del ícono a azul */
  margin-right: 8px; /* Añade un poco de espacio a la derecha del ícono */
  padding: 0px 5px;
  border-radius: 10px;
  font-weight: 500;
}

.e-grid .e-row .e-templatecell .interno {
  background-color: #f1f1f1;
  color: #9b9b9b; /* Cambia el color del ícono a azul */
  margin-right: 8px; /* Añade un poco de espacio a la derecha del ícono */
  padding: 0px 5px;
  border-radius: 10px;
  font-weight: 500;
}

.e-grid .e-row .e-templatecell .recibido-true {
  background-color: #e5fff1;
  color: #047857; /* Cambia el color del ícono a azul */
  margin-right: 8px; /* Añade un poco de espacio a la derecha del ícono */
  padding: 0px 5px;
  border-radius: 10px;
  font-weight: 500;
}

.e-grid .e-row .e-templatecell .recibido-false {
  background-color: #ffded9;
  color: #b44b4b; /* Cambia el color del ícono a azul */
  margin-right: 8px; /* Añade un poco de espacio a la derecha del ícono */
  padding: 0px 5px;
  border-radius: 10px;
  font-weight: 500;
}

.e-grid .e-row .e-templatecell .enviado p,
.e-grid .e-row .e-templatecell .interno p,
.e-grid .e-row .e-templatecell .recibido-true p,
.e-grid .e-row .e-templatecell .recibido-false p {
  margin-top: 8px !important;
  margin-bottom: 8px !important;

  /* Propiedades para la elipsis */
  white-space: nowrap; /* Evita que el texto se envuelva a la siguiente línea */
  overflow: hidden; /* Oculta cualquier texto que sobrepase el ancho del contenedor */
  text-overflow: ellipsis; /* Muestra "..." cuando el texto es demasiado largo */
}
