/* Estilo seleccion panel lateral */
.e-treeview .e-list-item.e-active > .e-fullrow {
  background-color: #7ef8da6b !important;
}

/* Estilo seleccion elemento en el panel principal */
.e-filemanager .e-large-icons .e-large-icon.e-active {
  background: #7ef8da6b !important;
  border-color: #63c2aa6b !important;
}

/* Estilo del tick de seleccion de elementos */
.e-filemanager .e-large-icons .e-checkbox-wrapper .e-icons.e-check {
  background-color: #00695c !important;
  color: white;
}

.e-filemanager {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

/* Bordes redondeados para la barra de herramientas cuando esta en modo movil*/
.e-filemanager.e-fe-mobile .e-toolbar {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

/* Bordes redondeados para la barra de herramientas */
.e-filemanager .e-toolbar {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

/* Sombra de los botones del toolbar - La quito */
.e-toolbar .e-toolbar-item .e-tbar-btn:focus {
  box-shadow: none;
}

/* Color de pulsar botones de herramientas */
.e-toolbar .e-toolbar-item .e-tbar-btn:active {
  background-color: #7ef8da1e;
}

/* Estilo spinner de carga */
.e-spinner-pane .e-spinner-inner .e-spin-material,
.e-spinner-pane .e-spinner-inner .e-spin-material3 {
  stroke: #61d1b5;
}

/* Estilos del panel principal en la vista de detalles para la seleccion */
.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: #00695c !important;
  border-color: #00695c !important;
  color: white;
}

.e-checkbox-wrapper .e-frame.e-stop,
.e-css.e-checkbox-wrapper .e-frame.e-stop {
  background-color: #00695c !important;
  border-color: #00695c !important;
  color: white;
}

.e-filemanager .e-grid td.e-active {
  background-color: #7ef8da6b !important;
}

/* Modal */
/* Color texto Okay o Crear */
/* Estado inicial */
.e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]) {
  color: #00695c;
  background-color: transparent; /* Fondo transparente por defecto */
  border-radius: 10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Transición suave */
}

/* Hover: Aparece el background y la sombra */
.e-footer-content .e-btn.e-primary:hover.e-flat:not([DISABLED]) {
  color: #00695c;
  background-color: #7fffd44d;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 105, 92, 0.3); /* Sombra verde sutil */
}

/* Focus: Mantiene el background y la sombra */
.e-footer-content .e-btn.e-primary:focus.e-flat:not([DISABLED]) {
  color: #00695c;
  background-color: #7fffd44d;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 105, 92, 0.3); /* Sombra verde */
}

/* Active (clic): Quita el shadow mientras se presiona el botón */
.e-footer-content .e-btn.e-primary:active.e-flat:not([DISABLED]) {
  color: #00695c;
  background-color: #7fffd44d;
  border-radius: 10px;
  box-shadow: none; /* Sin sombra durante el clic */
  transition: background-color 0.1s ease, box-shadow 0.1s ease; /* Respuesta rápida */
}

/* Volver al estado inicial tras el clic y al salir del hover */
.e-footer-content
  .e-btn.e-primary.e-flat:not([DISABLED]):not(:hover):not(:focus) {
  color: #00695c;
  background-color: transparent;
  box-shadow: none; /* Elimina cualquier sombra */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Suaviza el retorno */
}

/* Estado inicial */
.e-footer-content .e-btn.e-flat:not([DISABLED]) {
  color: #b71c1c; /* Rojo oscuro */
  background-color: transparent; /* Fondo transparente por defecto */
  border-radius: 10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Transición suave */
}

/* Hover: Aparece el background y la sombra */
.e-footer-content .e-btn.e-flat:hover:not([DISABLED]) {
  color: #b71c1c; /* Rojo oscuro */
  background-color: rgba(255, 0, 0, 0.3); /* Fondo rojo translúcido */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(183, 28, 28, 0.3); /* Sombra roja sutil */
}

/* Focus: Mantiene el background y la sombra */
.e-footer-content .e-btn.e-flat:focus:not([DISABLED]) {
  color: #b71c1c; /* Rojo oscuro */
  background-color: rgba(255, 0, 0, 0.3); /* Fondo rojo translúcido */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(183, 28, 28, 0.3); /* Sombra roja sutil */
}

/* Active (clic): Quita el shadow mientras se presiona el botón */
.e-footer-content .e-btn.e-flat:active:not([DISABLED]) {
  color: #b71c1c; /* Rojo oscuro */
  background-color: rgba(255, 0, 0, 0.3); /* Fondo rojo translúcido */
  border-radius: 10px;
  box-shadow: none; /* Sin sombra durante el clic */
  transition: background-color 0.1s ease, box-shadow 0.1s ease; /* Respuesta rápida */
}

/* Volver al estado inicial tras el clic y al salir del hover */
.e-footer-content .e-btn.e-flat:not([DISABLED]):not(:hover):not(:focus) {
  color: #b71c1c; /* Rojo oscuro */
  background-color: transparent; /* Fondo transparente */
  box-shadow: none; /* Elimina cualquier sombra */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Suaviza el retorno */
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(
    .e-float-input
  )::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background-color: #6bd3b9b4;
}

.e-input-group:focus-within:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:focus-within:not(.e-float-icon-left) {
  border: none !important;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Desplegable de ordenar por */
/* Estado inicial: Sin color de fondo */
.e-dropdown-popup ul .e-item {
  background-color: transparent;
  transition: background-color 0.3s ease; /* Transición suave */
}

/* Al hacer clic: Aparece un color de fondo */
.e-dropdown-popup ul .e-item:active {
  background-color: #7ef8da6b; /* Color de fondo al hacer clic */
  transition: background-color 0.1s ease; /* Respuesta rápida */
}

.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-hover,
.e-splitter .e-split-bar.e-split-bar-horizontal.e-split-bar-active {
  background-color: #0075586b;
}
