/* Estilos titulo editor */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
body {
  margin: 0;
}

.custom-title-button {
  cursor: default !important;
}

.custom-title-button button {
  height: 60px !important;
  max-height: 60px !important;
  cursor: default !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  border-radius: 4px !important;
  color: white !important;
  max-width: 220px; /* Límite de ancho */
  overflow: hidden !important; /* Oculta el texto que sobrepasa */
  white-space: nowrap !important; /* Mantiene el texto en una sola línea */
  text-overflow: ellipsis !important; /* Agrega los puntos suspensivos */
}

.custom-title-button .e-tbar-btn-text {
  display: -webkit-box !important; /* Habilita el modelo de caja flexible */
  -webkit-line-clamp: 2 !important; /* Número de líneas permitidas */
  -webkit-box-orient: vertical !important; /* Orientación vertical */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 220px !important;
  white-space: normal !important; /* Permite saltos de línea */
  text-transform: uppercase; /* Mantiene mayúsculas */
  font-weight: bold !important;
}

.custom-title-button .e-btn-icon {
  display: none !important;
}

/* Estilos boton guardar editor */
.custom-save-button {
  cursor: default !important;
  color: azure !important;
}

.custom-save-button button {
  color: azure !important;
  border-radius: 4px !important; /* Bordes redondeados */
  max-width: 200px;
}

.custom-save-button .e-btn-icon {
  color: #3c3c3c !important;
}

/* Estilos boton anexos editor */

.custom-anexos-button {
  cursor: default !important;
  margin-right: 20px !important;
}

.custom-anexos-button button {
  background-color: transparent !important;
  border-left: 1px solid #dddce0 !important;
  border-right: 1px solid #dddce0 !important;

  flex-direction: row-reverse !important;

  width: 180px !important;
  height: 80px !important;
  max-width: 220px;
  padding-top: 10px !important;
}

.custom-anexos-button .e-btn-icon {
  color: #49454e !important;
  font-size: 1.1rem !important;
}

.custom-anexos-button .e-tbar-btn-text {
  color: #49454e !important;
  font-size: 1.1rem !important;
}

.custom-anexos-button button:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2) !important;
}

.custom-anexos-button {
  cursor: default !important;
  margin-right: 20px !important;
}

/* Estilos boton anexos editor */

.custom-newversion-button {
  cursor: default !important;
  margin-right: 20px !important;
}

.custom-newversion-button button {
  background-color: #1976d2 !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 20px !important; /* Bordes redondeados */
  display: flex !important;
  flex-direction: row-reverse !important;
  align-items: center !important;
  width: 180px !important;
  height: 50px !important;
  max-width: 220px;
  padding-top: 10px !important;
}

.custom-newversion-button .e-btn-icon {
  color: #ffffff !important;
  font-size: 1.1rem !important;
}

.custom-newversion-button .e-tbar-btn-text {
  color: #ffffff !important;
  font-size: 1.1rem !important;
}

.custom-newversion-button button:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2) !important;
}
/* Estilos boton continuar editor */

.custom-continuar-button {
  cursor: pointer !important; /* Cambiar cursor para interacción */
  color: azure !important;
}

.custom-continuar-button button {
  color: azure !important;
  box-shadow: none !important; /* Quitar sombras */
  background: #61d1b5 !important; /* Cambiar a color verde */
  border-radius: 10px !important; /* Reducir bordes redondeados */
  display: flex !important;
  flex-direction: row-reverse !important;
  align-items: center !important;
  width: 120px !important; /* Reducir ancho del botón */
  height: 50px !important; /* Reducir altura del botón */
  padding: 5px 5px !important; /* Ajustar espacio interno */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.custom-continuar-button .e-btn-icon {
  color: #ffffff !important;
  font-size: 0.9rem !important; /* Reducir tamaño de ícono */
}

.custom-continuar-button .e-tbar-btn-text {
  color: #ffffff !important;
  font-size: 1rem !important; /* Reducir tamaño de texto */
}

.custom-continuar-button button:hover {
  background: #479984 !important; /* Un verde más oscuro en hover */
  box-shadow: none !important; /* Sin sombras adicionales */
}

@media (max-width: 1415px) {
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-separator {
    margin: 0px 6px;
  }
}

@media (max-width: 1360px) {
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-separator {
    margin: 0px 5px;
  }
}

@media (max-width: 1330px) {
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-separator {
    margin: 0px 4px;
  }
}

@media (max-width: 1315px) {
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-separator {
    margin: 0px 3px;
  }
}

@media (max-width: 1285px) {
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-separator {
    margin: 0px 2px;
  }
}

@media (max-width: 1200px) {
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-separator {
    margin: 0px 1px;
  }
}

@media (max-width: 1165px) {
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-separator {
    margin: 0px;
  }
}

@media (max-width: 1165px) {
  .e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child,
  .e-toolbar
    .e-toolbar-items:not(.e-tbar-pos):not(.e-toolbar-multirow)
    .e-toolbar-item:first-child {
    margin-left: 0px;
  }
}
/* Estilos cuando la pantalla es menor a 1300px */
@media (max-width: 1265px) {
  .custom-continuar-button.small button {
    width: 50px !important; /* Más estrecho */
    height: 50px !important;
    padding: 5px !important;
    justify-content: center !important; /* Centra el icono */
  }

  .custom-continuar-button.small .e-tbar-btn-text {
    display: none !important; /* Oculta el texto */
  }
}

.e-dlg-container {
  align-items: center !important;
  justify-content: center !important;
}

.custom-logo-toolbar-btn {
  width: 160px !important;
  padding: 0 !important;
}

.custom-logo-toolbar-btn {
  background-image: url("../../images/safeconsimbolo-removebg-preview.webp") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 45px !important;
  height: auto !important;
  display: inline-block !important;
}

.custom-aprobado-button {
  cursor: default !important;
}

.custom-aprobado-button button {
  height: 60px !important;
  cursor: default !important;
  text-transform: uppercase !important; /* Texto en mayúsculas */
  font-weight: bold !important; /* Negrita para el texto */
  border-radius: 4px !important; /* Bordes redondeados */
  color: white !important; /* Cambia el color del texto */
  background-color: #61d1b5 !important;
}

.custom-cancelado-button {
  cursor: default !important;
}

.custom-cancelado-button button {
  height: 60px !important;
  cursor: default !important;
  text-transform: uppercase !important; /* Texto en mayúsculas */
  font-weight: bold !important; /* Negrita para el texto */
  border-radius: 4px !important; /* Bordes redondeados */
  color: white !important; /* Cambia el color del texto */

  background-color: #ff9494 !important;
}

.custom-title-button .e-btn-icon,
.custom-save-button .e-btn-icon,
.custom-anexos-button .e-btn-icon,
.custom-newversion-button .e-btn-icon,
.custom-continuar-button .e-btn-icon {
  font-size: 0.8rem !important; /* Tamaño más pequeño */
}

/*Estilos para redondear los bordes del editor*/
.e-de-ctn {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.e-de-ctnr-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* Contenedor desde el titulo hasta el boton de compartir */
.e-de-tlbr-wrapper {
  border-top-left-radius: 10px;
  width: calc(100% - 40px) !important;
}

/* Contenedor del boton del panel lateral */
.e-de-ctnr-properties-pane-btn {
  border-top-right-radius: 10px;
  width: 40px;
  margin-left: auto;
}

/* Boton del panel lateral */
.e-de-ctnr-properties-pane-btn .e-btn {
  border-top-right-radius: 10px;
}

/* Contenedor desde el titulo hasta el boton de compartir e hijos */
.e-de-tlbr-wrapper .e-de-toolbar.e-toolbar,
.e-de-tlbr-wrapper .e-de-ctnr-properties-pane-btn {
  border-top-left-radius: 10px;
}

.e-de-ctnr-properties-pane-btn .e-btn-icon {
  font-size: 20px !important; /* Aumenta el tamaño del icono */
}

.custom-send-icon::before {
  font-size: 16px !important; /* Ajusta el tamaño que desees */
  /* Puedes añadir más estilos si lo necesitas */
}
