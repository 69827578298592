/* PaymentConfirmation.module.css */

/* Centrar todo el contenido en la pantalla */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #ffffff; /* Fondo blanco */
  text-align: center;
}

/* Estilo del mensaje principal (título) */
.title {
  color: #0DCA91;
  font-size: 48px;
  margin-top: 20px;
  font-weight: 500;
}

/* Estilo del mensaje secundario */
.message {
  color: #333333;
  font-size: 28px;
  margin-top: 20px;
  line-height: 1.5;
}

/* Estilo del correo electrónico resaltado */
.email {
  color: #4F67EC;
  font-weight: bold;
}

.iconcheck {
  color: #0DCA91; /* Color verde para el check */
  font-size: 14rem; /* Ajusta el tamaño del check para que se vea bien dentro del círculo */
  line-height: 1; /* Asegura que el check esté centrado correctamente */
}
