/* SimplifiedProcess.module.css */
.container {
  display: flex;

  color: #333;
  padding: 2rem;
  border-radius: 0.5rem;

  max-width: 80rem;

  margin: 0 auto;
  margin-bottom: 50px;
  gap: 25px;
}

.imageContainer {
  width: 60%;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.5rem;
}

.content {
  width: 35%;
}

.title {
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.highlight {
  font-weight: 600;
}

.bulletList {
  list-style-type: none;
  padding: 0;
}

.bulletItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #61d1b5;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

@media (max-width: 1060px) {
  .container {
    flex-direction: column;
  }

  .imageContainer,
  .content {
    width: 100%;
  }

  .imageContainer {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .icon {
    width: 1.25rem;
    height: 1.25rem;
  }
}
